import * as Sentry from '@sentry/browser'
import React from 'react'
import SEO from '@/components/Seo'
// import { withLayout } from '@/layout'
import { PageRendererProps } from 'gatsby'

interface Props extends PageRendererProps {
  localtion: any
}

const NotFound = ({ location }: Props) => (
  <>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </>
)

// const NotFoundPage = withLayout(NotFound)

export default NotFound
